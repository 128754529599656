<script setup>
    import {Splide, SplideSlide} from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';
    import {ref, onMounted, computed} from "vue";
    import {getNearByStudios} from "../../mixins/api";
    import Text from '../elements/Text.vue';
    import Button from '../elements/Button.vue';
    import Image from '../elements/Image.vue';
    import SectionContainer from '../containers/SectionContainer.vue';
    import ElementContaier from '../containers/ElementContaier.vue';
    import {useRoute, useRouter} from "vue-router";

    const route = useRoute();
    const queryParams = route.query;
    const router = useRouter();

    const sliderOptions = {
        arrowPath: 'M40.9782 16.6354L25.2042 0.861441C24.8137 0.470916 24.1805 0.470916 23.79 0.861441C23.3995 1.25197 23.3995 1.88513 23.79 2.27565L37.8574 16.343L2.41398 16.343C1.8617 16.343 1.41398 16.7907 1.41398 17.343C1.41398 17.8953 1.8617 18.343 2.41398 18.343H37.8564L23.79 32.4094C23.3995 32.8 23.3995 33.4331 23.79 33.8236C24.1805 34.2142 24.8137 34.2142 25.2042 33.8236L40.9525 18.0753C41.1488 17.8927 41.2716 17.6322 41.2716 17.343C41.2716 17.0668 41.1596 16.8168 40.9786 16.6358C40.9784 16.6357 40.9783 16.6356 40.9782 16.6354Z',
        rewind: true,
        type: 'loop',
        perPage: 1,
        autoplay: true,
        pagination: false,
        arrows: true,
        padding: '0px',
        focus: 'center',
        breakpoints: {
            640: {
                type: 'slide',
                perPage: 1,
                arrows: false,
                focus: 'left',
                autoplay: false,
            },
        },
    };

    const locations = ref({});
    const studios = ref([]);

    const getLatLang = () => {
        return new Promise((resolve) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    let pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    locations.value = pos;
                    resolve();
                }, function () {
                    console.log('Location cannot be found');
                    resolve();
                })
            } else {
                resolve();
            }
        })
    };

    const getStudios = () => {
        return getNearByStudios(locations.value.lat, locations.value.lng);
    };

    const getStudiosByHeadQuarter = () => {
        locations.value = {lat: 40.72897, lng: -72.96990}
        //locations.value = {lat: 34.1478279, lng: -118.093004};
        getStudios().then((response) => {
            studios.value = response;
        });
    };

    onMounted(() => {
        getLatLang().then(() => {
            if (locations.value.lat && locations.value.lng) {
                getStudios().then((response) => {
                    if (response?.length > 0) {
                        studios.value = response;
                    } else {
                        getStudiosByHeadQuarter();
                    }
                }).catch((err) => {
                    console.log("Error while fetching studios", err);
                    getStudiosByHeadQuarter();
                });
            } else {
                getStudiosByHeadQuarter();
            }
        });
    });

    // Current location state
    const currentLocation = computed(() => {
        return studios.value.length > 0
            ? 'near ' + studios.value[0].state.State
            : '';
    });

</script>

<template>
    <SectionContainer margin="0 0 24px 0">
        <ElementContaier class="studio-titleone">
            <Text type="H2" :text="'Studios ' + currentLocation"/>
            <div class="countnumber">{{ studios.length }}</div>
        </ElementContaier>
        <ElementContaier v-if="currentLocation" class="studiossubtitle" margin="0 0 16px 0" role="heading">
            <Text :text="'Not ' + currentLocation + '? Find your studio'"/>
          <router-link :to="{ path: '/find-location', query: queryParams }"  class="router-link btn-link" aria-label="Find your studio at a different location">
            here.
          </router-link>
        </ElementContaier>
    </SectionContainer>
    <SectionContainer>
        <ElementContaier>
            <div class="spgroup" v-if="studios.length > 0">
                <div class="sp-slider-wrp">
                    <Splide :options="sliderOptions">
                        <SplideSlide v-for="(studio) in studios" :key="studio.ID">
                            <div class="sp-box">
                                <router-link :to="{ path:'/' + studio?.url_slug, query: queryParams }">
                                    <div class="sp-card">
                                        <div class="sp-image" v-if="studio?.schoolBio?.image_link">
                                            <Image
                                                    :src="studio?.schoolBio?.image_link"
                                                    :text="studio.Name"
                                            />
                                        </div>
                                        <div class="sp-content">
                                            <Text type="H4" :text="studio.City+', '+studio.state.Code"/>
                                            <Text :text="studio.Line1"/>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </SplideSlide>
                    </Splide>
                </div>
            </div>
        </ElementContaier>
    </SectionContainer>
</template>