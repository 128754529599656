<script setup>
import {computed, defineProps, onMounted, ref} from "vue";
import {fetchStudioSchedule} from "@/mixins/api";
import Text from "./Text.vue";

const props = defineProps({
  currentStudio: {
    type: Object
  }
});

const schedule = ref([]);

// Current Studio Id
const studioId = computed(() => {
  return props?.currentStudio?.ID
      ? props?.currentStudio?.ID
      : 0;
});

const getStudioSchedule = () => {
  fetchStudioSchedule(studioId.value).then((response) => {
    schedule.value = {
      monday: JSON.parse(response.monday_name_time),
      tuesday: JSON.parse(response.tuesday_name_time),
      wednesday: JSON.parse(response.wednesday_name_time),
      thursday: JSON.parse(response.thursday_name_time),
      friday: JSON.parse(response.friday_name_time),
      saturday: JSON.parse(response.saturday_name_time),
      sunday: JSON.parse(response.sunday_name_time),
    };
  });
};

onMounted(() => {
  getStudioSchedule();
});

</script>
<template>
  <template v-if="currentStudio">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <template v-for="(schedules, index) in schedule" :key="index">
              <th>
                <span class="schedule-week-head">{{ index }}</span>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <template v-for="(schedules, index) in schedule.monday" :key="index">
                <div class="schedule-time-name">
                    <Text 
                      type="SPAN"
                      :text="schedules.class_name"
                      class="schedule-time-name-title"
                    />
                    <Text 
                      type="SPAN"
                      :text="schedules.class_time"
                    />
                </div>
              </template>
            </td>
            <td>
              <template v-for="(schedules, index) in schedule.tuesday" :key="index">
                <div class="schedule-time-name">
                    <Text 
                      type="SPAN"
                      :text="schedules.class_name"
                      class="schedule-time-name-title"
                    />
                    <Text 
                      type="SPAN"
                      :text="schedules.class_time"
                    />
                </div>
              </template>
            </td>           
            <td>
            <template v-for="(schedules, index) in schedule.wednesday" :key="index">
                <div class="schedule-time-name">
                    <Text 
                      type="SPAN"
                      :text="schedules.class_name"
                      class="schedule-time-name-title"
                    />
                    <Text 
                      type="SPAN"
                      :text="schedules.class_time"
                    />
                </div>
              </template>
            </td>
            <td>
            <template v-for="(schedules, index) in schedule.thursday" :key="index">
                <div class="schedule-time-name">
                    <Text 
                      type="SPAN"
                      :text="schedules.class_name"
                      class="schedule-time-name-title"
                    />
                    <Text 
                      type="SPAN"
                      :text="schedules.class_time"
                    />
                </div>
              </template>
            </td>
            <td>
            <template v-for="(schedules, index) in schedule.friday" :key="index">
                <div class="schedule-time-name">
                    <Text 
                      type="SPAN"
                      :text="schedules.class_name"
                      class="schedule-time-name-title"
                    />
                    <Text 
                      type="SPAN"
                      :text="schedules.class_time"
                    />
                </div>
              </template>
            </td>
            <td>
            <template v-for="(schedules, index) in schedule.saturday" :key="index">
                <div class="schedule-time-name">
                    <Text 
                      type="SPAN"
                      :text="schedules.class_name"
                      class="schedule-time-name-title"
                    />
                    <Text 
                      type="SPAN"
                      :text="schedules.class_time"
                    />
                </div>
              </template>
            </td>
            <td>
            <template v-for="(schedules, index) in schedule.sunday" :key="index">
                <div class="schedule-time-name">
                    <Text 
                      type="SPAN"
                      :text="schedules.class_name"
                      class="schedule-time-name-title"
                    />
                    <Text 
                      type="SPAN"
                      :text="schedules.class_time"
                    />
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
</template>