<script setup>
import { onBeforeMount, ref, onMounted, defineProps } from "vue";
//import CalendarIcon from "../../assets/svg/CalendarIcon.vue";
import CloseIcon from "../../assets/svg/CloseIcon.vue";
import { triggerTrackEvents } from "../../mixins/events_handler";
import Button from "./Button.vue";
import GetStarted from "./GetStarted.vue";
import Modal from "./Modal.vue";
// import ProfileIcon from "../../assets/svg/ProfileIcon.vue";

const props = defineProps({
    queryParams: {
        type: Object
    },
    currentStudio: {
        type: Object
    }
});

const franchiseUrl = process.env.VUE_APP_FRANCHISE_URL;
const giftUrl = process.env.VUE_APP_GIFT_CERTIFICATE_URL;
const isMobile = ref(false);
const checkIsMobile = () => {
    let width = window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    isMobile.value = width < 640 ? true : false;
};


const isModalOption = ref(false);
const showModalOption = () => {
    triggerTrackEvents('Header', 'Get Intro Offer', 'head_get_offers');
    isModalOption.value = true;
};
const closeModalOption = () => {
    isModalOption.value = false;
};

onBeforeMount(() => {
    window.addEventListener("resize", checkIsMobile);
});
onMounted(() => {
    checkIsMobile();
});
</script>


<template>
    <div class="navbar-main">
        <ul class="navbar-nav">
            <li class="nav-item">
                <router-link :to="{ path: '/find-location', query: props?.queryParams }"
                    class="nav-link">Studios</router-link>
            </li>
            <li class="nav-item">
                <router-link :to="{ path: '/action-packed-workout', query: props?.queryParams }"
                    class="nav-link">Workout</router-link>
            </li>
            <li class="nav-item">
                <router-link :to="{ path: '/day-one', query: props?.queryParams }" class="nav-link">Day
                    One</router-link>
            </li>
            <!-- <li class="nav-item">
                <a :href="franchiseUrl" target="_blank" class="nav-link"
                    aria-label="Own a Franchise (opens in a new tab)">Own a Franchise</a>
            </li> -->

            <li class="nav-item" v-if="!isMobile">
                <!-- <a :href="franchiseUrl" target="_blank" class="nav-link"
                    aria-label="Own a Franchise (opens in a new tab)">Own a Franchise</a> -->
                <div class="dropdown">
                    <a role="button" :href="franchiseUrl" target="_blank" class="nav-link dropbtn">Own a Franchise <span
                            class="arrow-down"></span></a>
                    <div class="dropdown-content ilkb-font">
                        <a :href="`${franchiseUrl}standard`" target="_blank">Standard</a>
                        <a :href="`${franchiseUrl}lightweight`" target="_blank">Lightweight</a>
                    </div>
                </div>
            </li>
            <li class="nav-item" v-else>
                <div class="dropdown">
                    <a role="button" class="nav-link dropbtn">Own a Franchise <span class="arrow-down"></span></a>
                    <div class="dropdown-content ilkb-font">
                        <a :href="franchiseUrl" target="_blank">Our Business Models</a>
                        <a :href="`${franchiseUrl}standard`" target="_blank">Standard</a>
                        <a :href="`${franchiseUrl}lightweight`" target="_blank">Lightweight</a>
                    </div>
                </div>
            </li>
            <!-- <li class="nav-item">
                <a :href="giftUrl" target="_blank" class="nav-link" aria-label="Gift Certificate (opens in a new tab)">Gift Certificate</a>
            </li> -->
      <!--<li class="nav-item">
                <router-link to="" class="nav-link">Shop</router-link>
            </li>-->
      <template v-if="isMobile">
        <!-- <li class="nav-item">
                    <router-link to="" class="nav-link">Login <span class="nav-icon profileicon"><ProfileIcon /></span></router-link>
                </li> -->
        <!-- <li class="nav-item">
                    <router-link to="" class="nav-link">Book a Class <span class="nav-icon"><CalendarIcon /></span></router-link>
                </li> -->
            </template>
            <template v-else>
                <li class="nav-item gettrialoffer_link">
                    <a href="#studio_products" v-if="currentStudio?.url_slug === 'oldsmar'"
                        style="text-decoration:none;">
                        <Button class="nav-link" text="Get Intro Offer" />
                    </a>
                    <Button v-else-if="currentStudio?.url_slug !=='oldsmar'" class="nav-link" @click="showModalOption"
                        text="Get Intro Offer" />
                </li>
            </template>
        </ul>
    </div>
    <Modal modalLarge="true" v-if="isModalOption">
        <template #body>
            <span class="modal-close" @click="closeModalOption">
                <CloseIcon />
            </span>
            <GetStarted :redirectedToCheckout="true" :redirectedToStudio="false" :currentStudio="currentStudio" />
        </template>
    </Modal>
</template>
